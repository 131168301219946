<template>
    <b-card v-if="address" :title="`IP-Adresse ${address.address}`">
        <b-row cols-md="2">
            <b-col>
                <b>Netzmaske</b>
                <p>{{ netmask }} (/{{ address.network_netmask }})</p>
            </b-col>
            <b-col>
                <b>Typ</b>
                <p>{{ address.address_type === 'IPV4' ? 'IPv4' : 'IPv6' }}</p>
            </b-col>
            <b-col>
                <b>Gateway</b>
                <p>{{ address.address_gateway }}</p>
            </b-col>
        </b-row>
        <b-button variant="primary" @click="deleteAddress">
            Löschen
        </b-button>
    </b-card>
</template>

<script>
import { IpAddress } from '@/core/services/store/models/ip_address'
import projectMixin from '@/core/mixins/projectMixin'

export default {
    name: 'AddressSingle',
    mixins: [projectMixin],
    data() {
        return {
            hiddenFields: ['$id', 'project_uuid']
        }
    },
    computed: {
        address() {
            const address = IpAddress.find(this.$route.params.address)
            if (!address) {
                IpAddress.fetchSingle(this.$route.params.address)
            }
            return address
        },
        netmask() {
            const mask = []
            let bitCount = this.address.network_netmask
            for (let i = 0; i < 4; i++) {
                const n = Math.min(bitCount, 8)
                mask.push(256 - Math.pow(2, 8 - n))
                bitCount -= n
            }
            return mask.join('.')
        }
    },
    methods: {
        async deleteAddress() {
            const result = await this.$swal({
                text: 'IP-Addresse löschen?',
                confirmButtonText: 'Bestätigen',
                cancelButtonText: 'Abbrechen',
                showConfirmButton: true,
                showCancelButton: true

            })
            if (result.isConfirmed) {
                const { address, project_uuid } = this.address
                await this.api.deleteAddress(address)
                await IpAddress.delete(address)
                await this.$router.push({
                    name: 'projects-single',
                    params: { uuid: project_uuid }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
